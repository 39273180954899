import { method } from 'lodash';
import {
    ENVIRONMENT,
    ENVIRONMENTS,
    AIRLINE,
    CMS_AIRLINE,
    VERSION_API_CMS,
    VERSION_API_IBE,
    LANGUAGE
} from '../../env';
//import ReactGA from 'react-ga';

function setEnvironment(urlsObject) {
    urlsObject.api = urlsObject.api.replace('{version}', VERSION_API_IBE).replace('{airline}', AIRLINE).replace('{lang}', LANGUAGE);
    switch (ENVIRONMENT) {
        case ENVIRONMENTS.DEV:
            urlsObject.host = "http://localhost:8080";
            break;
        case ENVIRONMENTS.SAND:
            urlsObject.host = "https://sandbox-booking.tarmexico.com";
            break;
        case ENVIRONMENTS.PROD:
            urlsObject.host = "https://booking.tarmexico.com";
            break;
        default:
            throw new Error("Environment not defined");
    }
    return urlsObject;
}

function setInvoiceEnvironment(urlsObject) {
    urlsObject.api = urlsObject.api.replace('{version}', VERSION_API_IBE).replace('{airline}', AIRLINE).replace('{lang}', LANGUAGE);
    switch (ENVIRONMENT) {
        case ENVIRONMENTS.DEV:
            urlsObject.host = "http://localhost:8443";
            break;
        case ENVIRONMENTS.SAND:
            urlsObject.host = "https://sandbox-invoice.tarmexico.com";
            break;
        case ENVIRONMENTS.PROD:
            urlsObject.host = "https://invoice.tarmexico.com";
            break;
        default:
            throw new Error("Environment not defined");
    }
    return urlsObject;
}

function setReservationsEnvironment(urlsObject) {
    urlsObject.api = urlsObject.api.replace('{version}', VERSION_API_IBE).replace('{airline}', AIRLINE).replace('{lang}', LANGUAGE);
    switch (ENVIRONMENT) {
        case ENVIRONMENTS.DEV:
            urlsObject.host = "http://localhost:8081";
            break;
        case ENVIRONMENTS.SAND:
            urlsObject.host = "https://sandbox-reservation.tarmexico.com";
            break;
        case ENVIRONMENTS.PROD:
            urlsObject.host = "https://reservation.tarmexico.com";
            break;
        default:
            throw new Error("Environment not defined");
    }
    return urlsObject;
}

function setCmsEnvironment(urlsObject) {
    urlsObject.api = urlsObject.api.replace('{version}', VERSION_API_CMS).replace('{airline}', CMS_AIRLINE).replace('{lang}', LANGUAGE);
    switch (ENVIRONMENT) {
        case ENVIRONMENTS.DEV:
            urlsObject.host = "http://localhost:80";
            break;
        case ENVIRONMENTS.SAND:
            urlsObject.host = "https://sandbox-cms.tarmexico.com";
            break;
        case ENVIRONMENTS.PROD:
            urlsObject.host = "https://cms.tarmexico.com";
            break;
        default:
            throw new Error("Environment not defined");
    }
    return urlsObject;
}

let cmsUrls = {
    host: '',
    api: '/cms/{version}/{airline}/{lang}',
    services: {
        i18: {
            path: '/i18',
            method: 'GET'
        },
        promos: {
            path: '/promos',
            method: 'GET'
        },
        promo: {
            path: '/promos/{id}',
            method: 'GET'
        },
        services: {
            path: '/services',
            method: 'GET'
        },
        content: {
            path: '/content/{contentId}',
            method: 'GET'
        },
        menu: {
            path: '/menu/{id}'
        },
        reset : {
            path : '/reset/instance',
            method : 'POST'
        },
        mdbanner: {
            path: '/mdbanner',
            method: 'GET'
        },
        destinations:{
            path: '/destinations',
            method: 'GET'
        },
        destinationContent: {
            path: '/blog/{contentId}',
            method: 'GET'
        },
        titles: {
            path: '/titles/{titleId}',
            method:'GET'
        },
        partners:{
            path: '/business_partners',
            method: 'GET'
        },
        dashboardMenu:{
            path: '/dashboard_menu',
            method: 'GET'
        },
        promoDeep: {
            path: '/promo_deeplink/{id}',
            method: 'GET'
        }
    }
}

let ibePublicUrls = {
    host: '',
    api: '/ibe/{version}/{airline}',
    services: {
        langs: {
            path: '/dictionaries/langs',
            method: 'GET'
        },
        translations: {
            path: '/{lang}/i18',
            method: 'GET'
        },
        airports: {
            path: '/dictionaries/airports',
            method: 'GET'
        },
        routes: {
            path: '/routes',
            method: 'GET'
        },
        operation: {
            path: '/operation/{origin}/{destination}',
            method: 'GET'
        },
        currencies: {
            path: '/dictionaries/currencies',
            method: 'GET'
        },
        createSession: {
            path: '/flow/create/session',
            method: 'POST'
        },
        sessionUpdate: {
            path: '/flow/{session}',
            method: 'PUT'
        },
        flights: {
            path: '/{session}/flow/flights',
            method: 'PUT'
        },
        quote: {
            path: '/{session}/quote/day/from/{origin}/to/{destination}/when/{date}?return={return}',
            method: 'GET'
        },
        calendar: {
            path: '/{session}/quote/calendar/from/{origin}/to/{destination}/when/{date}?return={return}',
            method: 'GET'
        },
        services: {
            path: '/{session}/{flightId}/services',
            method: 'GET'
        },
        booking: {
            path: '/{session}/booking',
            method: 'POST'
        },
        bookingCancellation: {
            path: '/{session}/booking',
            method: 'DELETE'
        },
        noSeats: {
            path: '/{session}/availability/seats',
            method: 'GET'
        },
        availableSeats: {
            path: '/{session}/availableseats',
            method: 'GET'
        },
        registerSeats: {
            path: '/{session}/registerseats',
            method: 'POST'
        },
        cancelSeats: {
            path: '/{session}/cancelseats',
            method: 'PUT'
        },
        inAuth: {
            path: '/{session}/payment/in-auth',
            method: 'GET'
        },
        payment: {
            path: '/{session}/payment/card',
            method: 'POST'
        },
        paymentConfigs: {
            path: '/{session}/payment-configs?bin={bin}',
            method: 'GET'
        },
        payment3DSecureConfirmation: {
            path: '/{session}/payment/verify/payment/card/{trxId}/{bid}',
            method: 'POST'
        },
        paymentStore: {
            path: '/{session}/payment/referred',
            method: "POST"
        },
        rappiCheckout: {
            path: '/{session}/payment/rappicheckout',
            method: 'POST'
        },
        rappiReturn: {
            path: '/{session}/resume',
            method: 'GET'
        },
        zenkiCheckout : {
            path:'/{session}/payment/zenki',
            method:'POST'
        },
        zenkiexp : {
            path:'/{session}/payment/zenki/increment/expire/time',
            method:'POST'
        },
        orkestaorder:{
            path : '/{session}/payment/orkesta/orders',
            method: 'POST'
        },
        orkestaSubmit:{
            path:'/{session}/payment/orkesta/submit',
            method:'POST'
        },
        orkestaCompleted: {
            path:'/{session}/payment/orkesta/complete',
            method:'POST'
        }
    }
};

let invoiceUrls = {
    host: '',
    api: '/ibe/{version}/{airline}',
    services: {
        newinvoice: {
            path: '/invoice/{tktinv}',
            method: 'POST'
        },
        /*searchinvoice: {
            path: '/invoice/{tktinv}',
            method: 'GET'
        },
        searchemd: {
            path: '/invoice/emds/{tktinv}',
            method: 'GET'
        },*/
        searchinvoice: {
            path: '/find/invoice/{tktinv}',
            method: 'POST'
        },
        searchemd: {
            path: '/invoice/emds/{tktinv}',
            method: 'POST'
        },
        newemd: {
            path: '/invoice/emd',
            method: 'POST'
        },
        usecfdi: {
            path: '/list/regimen/{regime}',
            method: 'GET'
        }
    }
};

let ibePrivateUrls = {
    host: '',
    api: '/ibe/{version}/{airline}',
    services: {

    }
}

let reservationsUrls = {
    host: '',
    api: '/ibe/{version}/{airline}',
    services: {
        getReservations: {
            path: '/reservations/{pnr}',
            method: 'GET'
        },
        cancelReservation: {
            path: '/reservations/{pnr}',
            method: 'DELETE'
        },
        sendReservationEmail: {
            path: '/reservations/{pnr}/locale/{lang}/email/{email}',
            method: 'POST'
        },
        getStorePdf: {
            path: '/reservations/{pnr}/pdf',
            method: 'GET'
        }

    }
}

cmsUrls = setCmsEnvironment(cmsUrls);
ibePublicUrls = setEnvironment(ibePublicUrls);
ibePrivateUrls = setEnvironment(ibePrivateUrls);
reservationsUrls = setReservationsEnvironment(reservationsUrls);
invoiceUrls = setInvoiceEnvironment(invoiceUrls);

class RequestManagerClass {


    /**
     * @param {Object} request
     * El parametro request es un objeto de la forma:
     * {
     *   serivceName: Nombre del servicio al que llama
     *   urlParams: Los parametros que van sobre la url y serán remplazados, van como diccionario. Ej. {origin: 'MTY', destination: 'QRO'}
     *   body: Objeto a mandar en la petición, si no está presente no se envía (form-data)
     * }
     * @param {function} onOk, función que se manda llamar cuando la petición se ejecuto de forma correcta
     * @param {function} onError, función que se manda llamar cuando la petición falló
     * @param {Array} aditionalHeaders, arreglo con headers adicionales para ejecutar el request
    */

    callCmsService = (request, onOk, onError, additionalHeaders = {}) => {
        let service = cmsUrls.services[request.serviceName];
        if (!service) {
            throw new Error("Service not found");
        }
        else {
            let url = cmsUrls.host + cmsUrls.api + this.replaceUrlParams(service.path, request.urlParams);
            this.httpRequest(url, service.method, request.body, onOk, onError, additionalHeaders);
        }
    }

    callAsyncCmsService = (request, additionalHeaders={}) => {
        let service = cmsUrls.services[request.serviceName];
        if(!service){
            throw new Error("Service not found");
        }
        else {
            let url = cmsUrls.host + cmsUrls.api + this.replaceUrlParams(service.path, request.urlParams);
            return this.asyncHttpRequest(url, service.method, request.body, additionalHeaders);
        }
    }

    /**
     * @param {Object} request
     * El parametro request es un objeto de la forma:
     * {
     *   serivceName: Nombre del servicio al que llama
     *   urlParams: Los parametros que van sobre la url y serán remplazados, van como diccionario. Ej. {origin: 'MTY', destination: 'QRO'}
     *   body: Objeto a mandar en la petición, si no está presente no se envía (form-data)
     * }
     * @param {function} onOk, función que se manda llamar cuando la petición se ejecuto de forma correcta
     * @param {function} onError, función que se manda llamar cuando la petición falló
     * @param {Object} aditionalHeaders, objeto con headers adicionales para ejecutar el request
    */
    callIbeService = (request, onOk, onError, additionalHeaders = {}) => {
        let service = ibePublicUrls.services[request.serviceName];

        //console.log('name: ' + request.serviceName + '  path: ' + service.path + '  urlParam: ' +  request.urlParam);
        if (!service) {
            throw new Error("Service not found");
        }
        else {
            let url = ibePublicUrls.host + ibePublicUrls.api + this.replaceUrlParams(service.path, request.urlParams);
            this.httpRequest(url, service.method, request.body, onOk, onError, additionalHeaders);
        }
    }

    callAsyncIbeService = (request, additionalHeaders={}) => {
        let service = ibePublicUrls.services[request.serviceName];
        if(!service){
            throw new Error("Service not found");
        }
        else {
            let url = ibePublicUrls.host + ibePublicUrls.api + this.replaceUrlParams(service.path, request.urlParams);
            return this.asyncHttpRequest(url, service.method, request.body, additionalHeaders);
        }
    }

    /**
     * @param {Object} request
     * El parametro request es un objeto de la forma:
     * {
     *   serivceName: Nombre del servicio al que llama
     *   urlParams: Los parametros que van sobre la url y serán remplazados, van como diccionario. Ej. {origin: 'MTY', destination: 'QRO'}
     *   body: Objeto a mandar en la petición, si no está presente no se envía (form-data)
     * }
     * @param {function} onOk, función que se manda llamar cuando la petición se ejecuto de forma correcta
     * @param {function} onError, función que se manda llamar cuando la petición falló
     * @param {Object} aditionalHeaders, objeto con headers adicionales para ejecutar el request
    */
    callInvoiceService = (request, onOk, onError, additionalHeaders = {}) => {
        let service = invoiceUrls.services[request.serviceName];

        //console.log('name: ' + request.serviceName + '  path: ' + service.path + '  urlParam: ' +  request.urlParam);
        if (!service) {
            throw new Error("Service not found");
        }
        else {
            let url = invoiceUrls.host + invoiceUrls.api + this.replaceUrlParams(service.path, request.urlParams);
            this.httpRequest(url, service.method, request.body, onOk, onError, additionalHeaders);
        }
    }

    /**
     * @param {Object} request
     * El parametro request es un objeto de la forma:
     * {
     *   serivceName: Nombre del servicio al que llama
     *   urlParams: Los parametros que van sobre la url y serán remplazados, van como diccionario. Ej. {origin: 'MTY', destination: 'QRO'}
     *   body: Objeto a mandar en la petición, si no está presente no se envía (form-data)
     * }
     * @param {function} onOk, función que se manda llamar cuando la petición se ejecuto de forma correcta
     * @param {function} onError, función que se manda llamar cuando la petición falló
     * @param {Object} aditionalHeaders, objeto con headers adicionales para ejecutar el request
    */
    callIbeReservationsService = (request, onOk, onError, additionalHeaders = {}) => {
        let service = reservationsUrls.services[request.serviceName];
        if (!service) {
            throw new Error("Service not found");
        }
        else {
            let url = reservationsUrls.host + reservationsUrls.api + this.replaceUrlParams(service.path, request.urlParams);
            this.httpRequest(url, service.method, request.body, onOk, onError, additionalHeaders);
        }
    }

    callAsyncIbeReservationsService = (request, additionalHeaders={}) => {
        let service = reservationsUrls.services[request.serviceName];
        if(!service){
            throw new Error("Service not found");
        }
        else {
            let url = reservationsUrls.host + reservationsUrls.api + this.replaceUrlParams(service.path, request.urlParams);
            return this.asyncHttpRequest(url, service.method, request.body, additionalHeaders);
        }
    }


    /**
     * @param {Object} request
     * El parametro request es un objeto de la forma:
     * {
     *   serivceName: Nombre del servicio al que llama
     *   urlParams: Los parametros que van sobre la url y serán remplazados, van como diccionario. Ej. {origin: 'MTY', destination: 'QRO'}
     *   body: Objeto a mandar en la petición, si no está presente no se envía (form-data)
     * }
     * @param {function} onOk, función que se manda llamar cuando la petición se ejecuto de forma correcta
     * @param {function} onError, función que se manda llamar cuando la petición falló
     * @param {Object} aditionalHeaders, objeto con headers adicionales para ejecutar el request
    */
    callIbePrivateService = (request, onOk, onError, additionalHeaders = {}) => {
        let service = ibePrivateUrls.services[request.serviceName];
        if (!service) {
            throw new Error("Service not found");
        }
        else {
            let url = ibePrivateUrls.host + ibePrivateUrls.api + this.replaceUrlParams(service.path, request.urlParams);
            this.httpRequest(url, service.method, request.body, onOk, onError, additionalHeaders);
        }
    }

    httpRequest(url, method, body, onOk, onError, additionalHeaders = {}) {
        additionalHeaders['Content-Type'] = 'application/json';
        let headers = new Headers(additionalHeaders);
        let requestProperties = {
            method: method,
            headers: headers,
            mode: "cors"
        };
        if (body) {
            requestProperties.body = body;
        }

        fetch(url, requestProperties)
            .then((response) => {
                if (response.ok && typeof onOk === 'function') {
                    this.parseResponse(response, onOk)
                }
                else if (typeof onError === 'function') {
                    /*ReactGA.event({
                        category: 'API Error',
                        action: 'Error executing request to: ' + url
                    });*/
                    this.parseResponse(response, onError);
                }
                /*else {
                    ReactGA.event({
                        category: 'API Error',
                        action: 'Error executing request to: ' + url
                    });
                }*/
            })
            .catch((error) => {
                console.error("Error executing request to: " + url);
                console.error(error);
                /*ReactGA.event({
                    category: 'API Error',
                    action: 'Error executing request to: ' + url
                });*/
                if (typeof onError === 'function') {
                    onError(error);
                }
            });
    }

    parseResponse(response, onParse) {
        let contentType = response.headers.get("content-type");
        // Parsear tipos de contenido especifico
        if (contentType && contentType.includes("application/json", "text/html")) {
            // Intentar parsear un JSON
            response.clone().json().then(function (data) {
                onParse(data);
            })
                // Si falla intentar parsear texto
                .catch(function () {
                    response.clone().text().then(function (data) {
                        onParse(data);
                    })
                })
                // Si falla regresar el response sin parsear
                .catch(function () {
                    onParse(response.clone());
                });
        }
        // Regresar el response sin parsear
        else {
            onParse(response);
        }
    }

    replaceUrlParams(url, params) {
        if (!params) {
            return url;
        }
        Object.keys(params).forEach(paramKey => {
            url = url.replace("{" + paramKey + "}", params[paramKey]);
        });
        return url;
    }

  //TODO PASS TO OTHER File
    /* ASYNC FUCNTIONS  */

    asyncHttpRequest(url, method, body, additionalHeaders={}){
        additionalHeaders['Content-Type'] = 'application/json';
        let headers = new Headers(additionalHeaders);
        let requestProperties = {
            method: method,
            headers: headers,
            mode: "cors"
        };
        if (body) {
            requestProperties.body = body;
        }

        return fetch(url, requestProperties)
            .then((response) => {
                if (response.ok) {
                return  this.asyncParseResponse(response)
                }
                else {
                    return  this.asyncParseResponse(response)
                }
            })
            .catch((error) => {
                console.error("Error executing request to: " + url);
                console.error(error);
                return {error};
        });
    }

    asyncParseResponse(response, error) {
        let contentType = response.headers.get("content-type");
        // Parsear tipos de contenido especifico
        if(contentType && contentType.includes("application/json", "text/html")){
            // Intentar parsear un JSON
            return response.clone().json().then(function (data) {
                return data;
            })
            // Si falla intentar parsear texto
            .catch(function(){
                response.clone().text().then(function(data) {
                    return {data, error};
                })
            })
            // Si falla regresar el response sin parsear
            .catch(function(){
                return {response, error};
            });
        }
        // Regresar el response sin parsear
        else{
            return response;
        }
    }
}

const RequestManager = new RequestManagerClass();

export default RequestManager;
export { RequestManager, cmsUrls, ibePublicUrls, ibePrivateUrls };
