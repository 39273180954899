// Country Catalog (ISO 3166-1)

export default {
    MX: {
        name: 'México',
        code: 'MX',
        code3: 'MEX',
        currency: 'MXN',
        currency_simbol: '$',
        phone: '+52',
        language: 'es',
        flag: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAB+UlEQVRIS+2WX0hTcRTHP7/trm1ubBqtq0F/MA2dEZVETKgQhNIaSiC99K74oIRoj9FDT9FDD0YM6r2oh3oohGYkkZgPWvbHEesPsSZbsduaMbf0xr31IAxuC/wZgeftxzmc8+Gc8zt8BQOoQuEBsBsLO7/tmpW7xNc40GcZL+BFAaVNiEFm/1TcyLTaAEZOA8IA0I1HY1uDJfFUq8FZvunHOy2D70ejpn8d4P/uwL3LYeoPtFPfUrrxa7IDz0eHyWlLtJy6VLJw0gBej1/F6Rbo+Mh8eshCUaEu2IGWnEIoWwge6TFhpAEYyeceR5iNjlC79yAvx0dpOnyUnFbg0OkINvsGuQA3Jx7hS9zB/yNFcXERT2WVWVBbcvO1pouToVa5APHJEbT5Z6Q+xHF5vORzWeyKg2Q8Rqh7kF2hfrkA0UiYSrWG+fgcvk0qFf5fHTCvmk1hf/iKXICxu2fx6xnezkyaO7DS0gQ41nlBLkBf5CJNhVcE3Muojjw270Y+foPPooqZ7wGu956RC3Du9g1iyYRZpKEiy/u8h/yy3Xw376hl6ESXXIB0NsutpxMkMl94l07hcTrZubmaOrWajn3NuB2Sv+HKmT95E2PP1u14Xa61u4TlKgKpl7AciHWAv+nAPxOloE+L37J8DAhazXb1VbE+XcTR/hNzZyKR0u2GHgAAAABJRU5ErkJggg=='
    },
    US: {
        name: 'United States of America',
        code: 'US',
        code3: 'USA',
        currency: 'USD',
        currency_simbol: '$',
        phone: '+1',
        language: 'en',
        flag: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAWCAYAAAChWZ5EAAABT0lEQVRIS2PMFlaJ3SwbPO8dqwgLAwgwM4Ephr//IDQRfCYONobqI7kQ9cSDl///M6QyyhmU/37LKAixnEzAyM7KUHummBzdLxl5TLv////zl2HnvBQGRkYGhpiGC2CDFtcbEM1nZmNhODXXgiQHTJm3FKwe7oBd81MYGBkYGKKhDljSYEA0n5mVheHUPHIdYNH7///P3wxi8iYk+xzmZVZudoZtpyJJCoG1959AQwDNAaT4HGYjGzc7w1ayHTCaBkbTwGgaQEsDJGVmqGKKygE+h8n///34xSAhZ8zA8J+BgYmNGVwC/v39l2g+Oy8Hw/q9gSS5HV4QNU2c+5+dnY0hNTqUJAMoVQyvCwbcAZ0soIBnYAhWlKHUUyTph0fBgDtgwKNgwB3QycLwgoGBQZykCKSe4meMHcwMvoyMDHMYGBjEqGcuUSY9+/+fIQMA7YEh8yRPGe0AAAAASUVORK5CYII='
    }
};
